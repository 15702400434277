input[type=text].accessCode {
    @apply 
    m-auto
    text-center 
    appearance-none 
    relative 
    block 
    w-full 
    px-3 
    py-2 
    border 
    rounded-md 
    focus:outline-none 
    focus:z-10 
    text-3xl 
    tracking-widest 
    font-black
    border-gray-300
    placeholder-gray-500
    text-gray-900
    focus:ring-indigo-500
    focus:border-indigo-500;
}
