.accessCodeMethod {
  width: 300px;
  @apply mt-1;
}

input.addDocument {
  border-width: 0px;
  border-bottom-width: 1px;
  background-color: inherit;
  @apply border-gray-300
    rounded-none
    shadow-none
    pl-0
    focus:ring-0
    focus:outline-none
    focus:border-gray-300
    focus:shadow-none;
}

.documentsList {
  background-color: #f8f8f8;
}
