.subNavigationMenu li {
  @apply inline-block mr-4;
}

.subNavigationMenu li.selected {
  @apply border-b-2 border-orange-400;
}

.tenantCard {
  @apply flex-none 
    h-40 
    w-40 
    p-4 
    border 
    rounded 
    text-center 
    cursor-pointer 
    hover:bg-gray-100
    hover:border-gray-300;
}
