.menuButton {
  @apply inline-flex 
    justify-center 
    w-full 
    px-4 
    py-2 
    text-sm 
    font-medium 
    text-white 
    rounded-md 
    bg-opacity-20 
    hover:bg-opacity-30 
    focus:outline-none;
}

.subMenu {
  @apply absolute 
    right-0 
    w-56 
    mt-2 
    origin-top-right 
    bg-white 
    divide-y 
    divide-gray-100 
    rounded-md 
    shadow-lg 
    ring-1 
    ring-black 
    ring-opacity-5 
    focus:outline-none;
}

.menuItem {
  @apply w-full text-left flex rounded-md items-center w-full px-2 py-2 text-sm
    hover:bg-gray-500 
    hover:text-white;
}

.menuButtonMobile {
  @apply bg-gray-800 
    inline-flex 
    items-center 
    justify-center 
    p-2 
    rounded-md 
    text-gray-400 
    hover:text-white 
    hover:bg-gray-700 
    focus:outline-none 
    focus:ring-2 
    focus:ring-offset-2 
    focus:ring-offset-gray-800 
    focus:ring-white;
}

.footerText {
  @apply text-gray-400
    flex 
    justify-center;
}

.footerLogo {
  width: 150px;
  text-align: center;
  margin-top: 15px;
}

.checkIndicator {
  @apply text-green-700
    w-6;
}

.menuPrimaryButton {
  @apply text-white
    bg-blue-600
    p-1
    px-2
    rounded-md;
}
