.btnBase {
  @apply w-full
        border
        border-transparent
        shadow-sm
        text-base
        font-medium
        focus:outline-none
        focus:ring-2
        focus:ring-offset-2
        disabled:opacity-50
        sm:text-sm
        hover:opacity-80;
}

.btnBase.fitted {
  @apply w-auto;
}

.btnStandard {
  @apply rounded-md
        /*group*/
        flex
        relative
        justify-center
        px-4
        py-2;
}

.btnRound {
  @apply inline-flex
        items-center
        p-1
        rounded-full
        bg-indigo-600
        hover:bg-indigo-700
        focus:ring-indigo-500;
}

.btnCancel {
  @apply btnBase
    btnStandard;

  @apply border-gray-300 
    hover:bg-gray-100;
}

.toggleButton input:checked ~ .dot {
  transform: translateX(100%);
}
.toggleButton input:checked ~ .line {
  @apply bg-blue-700;
}
