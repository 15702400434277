@tailwind base;

/* custom base styles go here */

input[type="text"],
input[type="password"],
select,
textarea {
  @apply p-1 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border border-gray-300 rounded-md;
}

@tailwind components;

/* custom component styles go here */

.brandingLogo {
  @apply max-h-8;
}

input[type="text"].searchBar {
  @apply w-80;
}

.dataTable th,
.dataTable td {
  @apply border-b
    p-4;
}

.dataTable th {
  @apply pb-1
    font-normal
    text-gray-600;
}

.dataTable th.sortable {
  @apply cursor-pointer;
}

.dataTable tr:nth-child(even) {
  @apply bg-blue-50;
}

.dataTable .subtext {
  @apply text-gray-400;
}

.dataTable.loading td,
.dataTable.loading .subtext {
  @apply text-gray-300;
}

.modalInstructions {
  @apply p-2
    bg-gray-100
    rounded-md;
}

.formSection {
  @apply md:grid md:grid-cols-3 md:gap-6;
}

.formSectionLeft {
  @apply md:col-span-1;
}

.formSectionRight {
  @apply mt-5 md:mt-0 md:col-span-2;
}

.formSectionRightPanel {
  @apply shadow sm:rounded-md sm:overflow-hidden px-4 py-5 bg-white space-y-6 sm:p-6;
}

.formSectionHeader {
  @apply text-lg font-medium leading-6 text-gray-900;
}

.formSectionHeaderDescription {
  @apply mt-1 max-w-2xl text-sm text-gray-500;
}

.detailsSection {
  @apply shadow p-4 bg-white rounded-md;
}

.detailsHeader {
  @apply text-lg font-medium leading-6 text-gray-900 mb-1;
}

@tailwind utilities;

/* custom utliities styles go here */

a.colored {
  @apply text-blue-700;
}

input[type="text"],
input[type="password"] {
  @apply w-full;
}

.formField {
  @apply mt-4;
}

.formField input[type="text"],
input[type="password"],
textarea {
  @apply w-80;
}

.formField:first-child {
  @apply mt-0;
}

.formLabel {
  @apply block mb-1 text-sm font-medium text-gray-700;
}

.formHelpText {
  @apply mb-1 text-sm text-gray-500;
}

.pageTitle {
  @apply text-lg
    font-bold;
}

.navigationPanel .link {
  border-left: 2px solid transparent;
  @apply p-1 pl-4 pr-4 mb-1 rounded-sm;
}

.navigationPanel .link.selected {
  border-left: 2px solid #ff6a00;
  @apply bg-white;
}

.pagination {
  @apply text-gray-400
    p-2
    pl-4
    text-sm
    flex;
}

.pageCounts {
  @apply mr-1;
}

.pagination .pageDirection {
  @apply text-gray-700
        cursor-pointer
        p-1
        rounded-md
        hover:bg-gray-100;
}

.pagination .pageDirection svg {
  width: 16px;
}

.pagination .pageDirection.disabled {
  @apply text-gray-400
            cursor-default
            hover:bg-transparent;
}

.itemActions,
.actionButton {
  @apply cursor-pointer 
    p-2;
}

.itemActions svg,
.actionButton svg {
  width: 16px;
  @apply text-gray-500;
}

.itemActions:hover svg,
.actionButton:hover svg {
  @apply text-gray-400;
}

.segmentedProgressMeter {
  height: 10px;
  @apply flex;
}

.segmentedProgressMeter .segment {
  @apply flex-1
    bg-gray-100
    border-r
    border-b
    border-t;
}

.segmentedProgressMeter .segment:first-child {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  @apply border-l;
}

.segmentedProgressMeter .segment:last-child {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.segmentedProgressMeter .segment.filled {
  @apply bg-green-500
    border-green-600;
}

.breadcrumbs {
  @apply mb-4;
}
.breadcrumb {
  @apply text-sm font-medium text-gray-500;
}

a.breadcrumb {
  @apply hover:text-gray-700;
}

.quote {
  @apply border-l-2
    pl-2;
}

.pageTab {
  @apply text-gray-900 border-transparent;
}

.pageTab.selected {
  @apply border-orange-400;
}

.inlineItem,
.inlineFileItem {
  @apply inline-flex items-center px-3 py-1.5 text-xs font-medium rounded-full bg-gray-100 hover:bg-gray-200;
}

.inlineFileItem.purged {
  @apply hover:bg-gray-100;
}

.itemMenuButton {
  @apply rounded-full 
    flex 
    items-center 
    text-gray-500 
    p-1
    hover:bg-gray-100
    focus:bg-gray-100;
}

.itemMenuButton.onGrey {
  @apply hover:bg-gray-200 focus:bg-gray-200;
}

.dataTable tr:nth-child(even) .itemMenuButton {
  @apply hover:bg-white focus:bg-white;
}
